<template>
  <sign-page
    title-text="信息变更审批"
    :request="request"
    :column-list="columnList"
    table-size="large"
    v-loading="loadingDetail"
    :titleMenus="[]"
    :tableActions="tableActions"
    @tableAction="tableAction"
    :tableActionsFixed="false"
    :need-data-file="true">
    <check-modify
      v-if="openDetial"
      :wait-data="chooseData ? chooseData.dataMap : null"
      :open-dialog="openDetial"
      @dataUpdate="dataUpdate"
      :worker-name="chooseData ? chooseData.workerName : ''"
      @handleClose="openDetial = false">
    </check-modify>
  </sign-page>
</template>

<script>
import {
  dataAppleRequest
} from '../../api'

import CheckModify from '../../components/data-apply/check.vue'

import columns from '../../components/data-apply/columns.js'

export default {
  components: {
    CheckModify
  },
  methods: {
    async dataUpdate () {
      await this.pageVm.loadData()
      let findData = this.pageVm.dataList.find(v => v.workerId === this.chooseData.workerId)
      if (findData) {
        this.loadDetail(this.chooseData)
      } else {
        this.$notify({
          title: '系统提示',
          message:  this.chooseData.workerName + '的变更申请已全部审核完成。',
          type: 'success'
        })
        this.openDetial = false
        this.chooseData = null
      }
    },
    async tableAction (parm, pageVm) {
      this.pageVm = pageVm
      await this.loadDetail(parm.data)
      this.openDetial = true
    },
    async loadDetail (chooeData) {
      this.loadingDetail = true
      let data = chooeData
      let parm = {
        workerId: data.workerId
      }
      let detailList = await dataAppleRequest.getWaitDetail(parm)
      data.dataMap = {}
      detailList.forEach(v => {
        let waitKeys = Object.keys(v.modifyApply.newData ? JSON.parse(v.modifyApply.newData) : {})
        let dataItem = this.getDetail(v, waitKeys, v.modifyApply.dataType === 'worker')
        if (data.dataMap[v.modifyApply.dataType]) {
          data.dataMap[v.modifyApply.dataType].push(dataItem)
        } else {
          data.dataMap[v.modifyApply.dataType] = [dataItem]
        }
      })
      this.chooseData = data
      this.loadingDetail = false
    },
    getDetail (v, waitKeys, justWait) {
      let dataItem = v.modifyApply
      dataItem.newDataInfo = v.modifyApplyInfo
      dataItem.currentData = v.sourceInfo
      let column = (columns.find(c => dataItem.dataType === c.key) || {columns: null}).columns
      if (justWait) {
        column = column.filter(c => waitKeys.includes(c.field))
      }
      if (column) {
        dataItem.details = column.map((column) => {
          let updateText = null
          if (dataItem.type === 'del') {
            updateText = null
          } else if (dataItem.type === 'edit') {
            if (waitKeys.includes(column.field)) {
              if (dataItem.newDataInfo && dataItem.newDataInfo[column.field]) {
                updateText = column.text ? column.text.bind(this)(dataItem.newDataInfo) : dataItem.newDataInfo[column.field]
              }
            }
          } else {
            updateText = column.text ? column.text.bind(this)(dataItem.newDataInfo) : dataItem.newDataInfo[column.field]
          }
          return {
            title: column.title,
            current: dataItem.type === 'add' || !dataItem.currentData ? '' : (column.text ? column.text.bind(this)(dataItem.currentData) : dataItem.currentData[column.field]),
            updateText: updateText,
            applyKey: waitKeys.includes(column.field) ? column.field : null,
            render: column.render || null
          }
        })
      }
      return dataItem
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'detail',
        label: '明细'
      }]
    },
    columnList () {
      let data = [{
        title: '姓名',
        field: 'workerName',
        sort: true
      },
      {
        title: '身份证',
        field: 'idNo',
        sort: true
      },
      {
        title: '变更内容',
        field: 'num',
        filterRange: true,
        dataType: Number,
        render (h, rowData) {
          return h('div', '共' + rowData.num + '条')
        }
      },
      {
        title: '最新变更时间',
        filterRange: true,
        field: 'maxStatusTime',
        dataType: Date
      }]
      return data
    }
  },
  data () {
    return {
      chooseData: null,
      loadingDetail: false,
      pageVm: null,
      request: Object.assign({}, {
        get: dataAppleRequest.getWait
      }),
      openDetial: false
    }
  }
}
</script>
