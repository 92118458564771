<style scoped lang="less">
.detial-item {
  padding-bottom: 2rem;
}
.btm-actions {
  display: flex;
  justify-content: center;
}
</style>

<template>
  <fm-modal :mask-closable="true" :value="openDialog" :title="'信息变更明细（' + workerName + '）'" @cancel="handleClose" width="80%">
    <div v-loadingx="loadIng" v-for="configItem in configList.filter(v => waitData && waitData[v.dataKey])" :key="configItem.dataKey">
      <div class="detial-item" v-for="(item, index) in waitData[configItem.dataKey]" :key="index">
        <fm-title :title-text="configItem.title + (configItem.getLabel ? configItem.getLabel(item) : '')" @clickTitleMenu="clickTitleMenu($event, item)" :title-menus="[{key: 'pass', label: '通过'}, {key: 'down', label: '驳回'}]">
          <modify-apply-label :data-status="{type: item.type}">
          </modify-apply-label>
        </fm-title>
        <UpdateDetails
          :content="item.details"
          :apply-type="item.type"
          @checkField="checkField($event, item)">
        </UpdateDetails>
        <!-- <ApplyFile
          :source-dir="getDirPath(item)"
          v-if="getDirPath(item)"
          :funs="funs"
          :allData="getAllData(item)"
          label="扫描件"></ApplyFile> -->
      </div>
    </div>
    <div class="btm-actions">
      <fm-btn style="margin-right: 2rem;" @click="allCheck('pass')">全部通过</fm-btn>
      <fm-btn @click="allCheck('down')">全部驳回</fm-btn>
    </div>
  </fm-modal>
</template>

<script>
import {
  dataAppleRequest
} from '../../api'

import UpdateDetails from './details.vue'

// import ApplyFile from './file/index.vue'

import ModifyApplyLabel from '../../views/person/edit_info/cmp/modifyApplyLabel'

export default {
  components: {
    UpdateDetails,
    ModifyApplyLabel,
    // ApplyFile
  },
  props: {
    waitData: { type: Object },
    workerName: { type: String },
    openDialog: {
      type: Boolean
    }
  },
  data () {
    return {
      funs: {
        get: true,
        upload: false,
        down: true,
        del: false,
        mkdir: false
      },
      loadIng: false,
      configList: [{
        title: '基础信息/工作信息',
        dataKey: 'worker'
      },
      {
        title: '教育经历',
        dataKey: 'worker_edu'
      },
      {
        title: '工作记录',
        dataKey: 'his_work'
      },
      {
        title: '奖励信息',
        dataKey: 'rew'
      },
      {
        title: '培训信息',
        dataKey: 'train'
      },
      {
        title: '继续教育',
        dataKey: 'worker_edu_continue'
      },
      {
        title: '岗位信息',
        dataKey: 'worker_job_title'
      },
      {
        title: '专业技术资格证书',
        dataKey: 'worker_certificate_pro_qua'
      },
      {
        title: '执业证书',
        dataKey: 'worker_certificate_doctor_pra'
      },
      {
        title: '职业资格证书',
        dataKey: 'worker_certificate_doctor_qua'
      },
      {
        title: '专业技术职务聘任证书',
        dataKey: 'worker_certificate_pro_hire'
      },
      {
        title: '其他证书',
        dataKey: 'worker_certificate_other'
      }]
    }
  },
  watch: {
    waitData () {
      this.loadIng = false
    }
  },
  methods: {
    getDirPath (data) {
      let fConfig = this.configList.find(v => v.dataKey === data.dataType)
      return fConfig && fConfig.pDir ? fConfig.pDir : null
    },
    getAllData (data) {
      let d = {
        source: data.dataId ? {id: data.dataId} : null,
        wait: data
      }
      return d
    },
    async allCheck (status) {
      this.loadIng = true
      let keys = Object.keys(this.waitData)
      let i = 0
      while (i < keys.length) {
        let key = keys[i]
        let j = 0
        while (j < this.waitData[key].length) {
          let waitItem = this.waitData[key][j]
          let parm = {
            status: status,
            keys: waitItem.type === 'edit' ? Object.keys(JSON.parse(waitItem.newData)) : undefined
          }
          await dataAppleRequest.check(waitItem.id, {
            status: parm.status,
            keys: parm.keys ? parm.keys : (parm.key ? [parm.key] : undefined),
            statusTime: waitItem.statusTime
          })
          j += 1
        }
        i += 1
      }
      this.$emit('dataUpdate')
    },
    clickTitleMenu (key, waitData) {
      this.checkField({
        status: key,
        keys: waitData.type === 'edit' ? Object.keys(JSON.parse(waitData.newData)) : undefined,
      }, waitData)
    },
    async checkField (parm, waitData) {
      this.loadIng = true
      await dataAppleRequest.check(waitData.id, {
        status: parm.status,
        keys: parm.keys ? parm.keys : (parm.key ? [parm.key] : undefined),
        statusTime: waitData.statusTime
      })
      await this.$emit('dataUpdate')
    },
    handleClose () {
      this.$emit('handleClose')
    }
  }
}
</script>
